<template>
  <layout-wrapper class="bg-[#f8f8f8]">
    <template #header>
      <div class="flex justify-center items-center px-3">
        <nuxt-link :to="$routerUrl('/categories')">
          <van-icon :name="$publicPath('/images/header/category.svg')" size="24"/>
        </nuxt-link>
        <home-search/>
        <nuxt-link :to="$routerUrl('/notifications')">
          <van-icon :name="$publicPath('/images/header/notification.svg')" size="24"/>
        </nuxt-link>
      </div>

      <van-tabs v-if="tabs.length > 1" v-model:active="tabId" line-height="0">
        <van-tab v-for="(tab, index) in tabs" :key="`tab-${index}`" :name="tab.tab_id"
                 :title="tab.title">
        </van-tab>
      </van-tabs>
    </template>

    <div class="custom-container mt-3 mb-6">
      <dynamic-components :components-data="getActiveTabComponents"/>
    </div>

    <full-page-loader :loading="isLoading"/>

    <!-- For showing video popup based on query params video_id -->
    <video-popup/>


    <!-- <initial-popup/>-->
  </layout-wrapper>
</template>

<script setup>
import HomeSearch from "@/components/home/Search"
import VideoPopup from "@/components/home/VideoPopup"
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {useHomeStore} from "@/store/home";
import {useCustomFetch} from "@/composables/useFetch";

const homeStore = useHomeStore()
const {tabId, persistentTab} = storeToRefs(homeStore)

const {
  loading: homeDataLoading,
  data: homeData
} = await useCustomFetch('?route=rest/product_admin/app_mainScreen_v2')
const tabs = computed(() => homeData.value?.tabs || [])

const tabLoading = ref(false)

// get default tab value
const defaultTabId = computed(() => tabs.value.length && tabs.value.find(item => Number(item.default_selected))?.tab_id || '')
tabId.value = persistentTab.value || defaultTabId.value

const {
  loading: tabsDataLoading,
  data: tabsComponents,
} = await useCustomFetch('?route=rest/product_admin/tabComponents', {tab_id: tabId})
const isLoading = computed(() => homeDataLoading.value || tabsDataLoading.value || false)

const getActiveTabComponents = computed(() => {
  if (!tabs.value?.length || tabLoading.value || tabsDataLoading.value) return []
  if (tabs.value.length === 1) return tabs.value[0]?.components

  const tabsData = tabs.value.find(item => item?.tab_id === tabId?.value)?.components
  return tabsData?.length ? tabsData : tabsComponents.value?.components
})

watch(tabId, (tabId) => {
  tabLoading.value = true

  const {en_name: name} = tabs.value?.find(item => item?.tab_id === tabId) || {}
  track(TRACKERS.VIEW_HOME_TAB, {name, tabId})

  // for make home tab selection persistent
  persistentTab.value = tabId

  // scroll to top
  window.scrollTo({
    top: 0,
    left: 0
  });

  nextTick(() => {
    tabLoading.value = false
  })
})
</script>